import { PageOptions } from '@graphcommerce/framer-next-pages'
import { ProductListQuery } from '@graphcommerce/magento-product'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { GetStaticProps, JsonLd, LayoutHeader, MetaRobots, PageMeta } from '@graphcommerce/next-ui'
import { LayoutNavigation, LayoutNavigationProps, RowProduct, RowRenderer } from '../components'
import { LayoutDocument } from '../components/Layout/Layout.gql'
import { DefaultPageDocument, DefaultPageQuery } from '../graphql/DefaultPage.gql'
import { graphqlSharedClient, graphqlSsrClient } from '../lib/graphql/graphqlSsrClient'
import { RowButtonLinkListFAQHome } from '../components/GraphCMS/RowButtonLinkListFAQHome'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

type Props = DefaultPageQuery & {
  latestList: ProductListQuery
  favoritesList: ProductListQuery
  swipableList: ProductListQuery
}
type RouteProps = { url: string }
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, Props, RouteProps>

function CmsPage(props: Props) {
  const { pages, latestList, favoritesList, swipableList } = props
  const page = pages?.[0]

  const router = useRouter()
  const latest = latestList?.products?.items?.[0]
  const favorite = favoritesList?.products?.items?.[0]
  const swipable = swipableList?.products?.items?.[0]

  useEffect(() => {
    const hash = router.asPath
    if (hash.includes('single-filter')) {
      const arr: any = hash.split('::')
      if (arr[1]) {
        const handleElement = document.getElementById('handleOpenMenu')
        if (!handleElement) {
          return
        }
        handleElement.setAttribute('paths', arr[1])
        handleElement.click()
      }
    }
  }, [])

  const schemaJson = JSON.parse(page?.schemaOrg ?? '{}')
  return (
    <>
      <JsonLd
        item={{
          '@context': 'https://schema.org',
          ...schemaJson,
        }}
      />
      <PageMeta
        title={page?.metaTitle ?? page?.title ?? ''}
        metaDescription={page?.metaDescription ?? ''}
        metaRobots={page?.metaRobots.toLowerCase().split('_') as MetaRobots[] | undefined}
        canonical='/'
      />

      <LayoutHeader floatingMd floatingSm />

      {page && (
        <div className={`home-page`}>
          <RowRenderer
            content={page.content}
            renderer={{
              RowProduct: (rowProps) => {
                const { identity } = rowProps

                if (identity === 'home-favorites')
                  return (
                    <RowProduct {...rowProps} {...favorite} items={favoritesList.products?.items} />
                  )
                if (identity === 'home-latest')
                  return <RowProduct {...rowProps} {...latest} items={latestList.products?.items} />
                if (identity === 'home-swipable')
                  return (
                    <RowProduct {...rowProps} {...swipable} items={swipableList.products?.items} />
                  )
                return (
                  <RowProduct {...rowProps} {...favorite} items={favoritesList.products?.items} />
                )
              },
              RowButtonLinkList: (rowProps) => {
                return <RowButtonLinkListFAQHome {...rowProps} />
              },
            }}
          />
        </div>
      )}
    </>
  )
}

CmsPage.pageOptions = {
  Layout: LayoutNavigation,
} as unknown as PageOptions

export default CmsPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)

  const conf = client.query({ query: StoreConfigDocument })
  const page = staticClient.query({ query: DefaultPageDocument, variables: { url: `page/home` } })
  const layout = staticClient.query({ query: LayoutDocument })

  if (!(await page).data.pages?.[0]) return { notFound: true }

  return {
    props: {
      ...(await page).data,
      ...(await layout).data,
      latestList: {},
      favoritesList: {},
      swipableList: {},
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 60 * 20,
  }
}
