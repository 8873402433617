import { Box, Button, Container, Typography } from '@mui/material'
import { RowButtonLinkList, RowButtonLinkListProps } from '../RowButtonLinkList/RowButtonLinkList'
import { Trans } from '@lingui/react'
import { IconSvg } from '@graphcommerce/next-ui'
import iconChevronRight from '../../Common/Icons/ChevronRight/chevron-right-icon.svg'

export function RowButtonLinkListFAQHome(props: RowButtonLinkListProps) {
  return (
    <Box
      className='faq-home-section'
      sx={(theme) => ({
        backgroundColor: '#F9F9F9',
        paddingTop: theme.spacing(3.5),
        paddingBottom: theme.spacing(3.5),
        '& .row-button-link-list': {
          '& > div': {
            marginBottom: 0,
          },
          '& h2': {
            fontSize: '22px',
            fontWeight: '700',
            color: theme.palette.filtoria.grey.darkest,
            textTransform: 'unset',
            letterSpacing: 'normal',
          },
          '& .MuiListItemButton-root': {
            paddingY: theme.spacing(3),
            '& .MuiListItemText-root': {
              marginY: 0,
              '& .MuiListItemText-primary': {
                fontSize: '16px',
              },
            },
            '& .MuiListItemIcon-root': {
              textAlign: 'right',
              justifyContent: 'end',
              color: theme.palette.filtoria.primary.dark,
              fontSize: '24px',

              '& .IconSvg-root': {
                fontSize: '24px',
              },
            },
          },
        },
        '& .faq-sub-bold': {
          fontVariationSettings: "'wght' 700",
        },
      })}
    >
      <RowButtonLinkList {...props} />
      <Container className='bottom-content' maxWidth={false}>
        <Box
          sx={() => ({
            marginTop: {
              xs: '32px',
              md: '48px',
            },
          })}
        >
          <Typography
            variant='subtitle1'
            className='faq-sub-bold'
            sx={{
              fontSize: {
                xs: '16px',
                md: '20px',
              },
            }}
          >
            <Trans id="Can't find what you're looking for?" />
          </Typography>
          <Button
            href='/service'
            endIcon={
              <IconSvg
                src={iconChevronRight}
                size='inherit'
                sx={(theme) => ({
                  color: theme.palette.filtoria.primary.dark,
                  fontSize: '24px!important',
                })}
              />
            }
            sx={{
              marginTop: {
                xs: '16px',
                md: '20px',
              },
              fontSize: {
                xs: '16px',
                md: '20px',
              },
              textDecoration: 'underline',
              paddingX: 0,
            }}
          >
            <Trans id='Go to customer service' />
          </Button>
        </Box>
      </Container>
    </Box>
  )
}
